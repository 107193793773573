import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import * as playerState from "../redux/slices/playerSlice";

const AtunwaPlayerMeta = () => {

    const config = useSelector(playerState.selectConfig);

    useEffect(() => {

        if (!!config?.playerName) {
            if ('mediaSession' in navigator) {
                // Set media metadata (this will show in the notification tray)
                navigator.mediaSession.metadata = new window.MediaMetadata({
                    title: config?.playerName || 'Unknown Title',
                    artist: config?.publisher?.name || 'Unknown Artist',
                    album: config?.playerName || ' - ',
                    artwork: [
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '96x96',
                            type: 'image/png'
                        },
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '128x128',
                            type: 'image/png'
                        },
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '192x192',
                            type: 'image/png'
                        },
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '256x256',
                            type: 'image/png'
                        },
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '384x384',
                            type: 'image/png'
                        },
                        {
                            src: config.logo ?? "https://atunwadigital.com/wp-content/uploads/2022/04/Alternative-Logo-3-1.png",
                            sizes: '512x512',
                            type: 'image/png'
                        }
                    ]
                });
            }
        }

    }, [config.logo, config.playerName]);

        return (
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{config?.playerName}:- {config?.publisher?.name}</title>
                    <link rel="canonical" href={window.location.href}/>
                    <meta name="description" content={config?.playerName}/>
                    <meta property="twitter:title" content={config.playerName}/>
                    <meta property="twitter:description" content={config.playerName}/>
                    <meta property="twitter:url" content={window.location.href}/>
                    <meta property="twitter:image" content={config?.logo}/>
                    <meta property="og:type" content="ARTICLE"/>
                    <meta property="og:title" content={config.playerName}/>
                    <meta property="og:description" content={config.playerName}/>
                    <meta property="og:site_name" content={config.playerName}/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta property="og:image" content={config.logo}/>
                    <meta property="og:image:secure_url"
                          content={window.location.href}/>
                </Helmet>
            </div>
        );
};

export default AtunwaPlayerMeta;
import { Box, Flex, Image, Stack, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../config";
import PoweredByLogo from "../../assets/icons/PoweredByLogo";
import { usePlayerContext } from "../../providers/PlayerProvider.tsx";
import * as adsState from "../../redux/slices/adsSlice";
import {
  selectConfig,
  selectConfigError,
  setError,
} from "../../redux/slices/playerSlice";
import { urls } from "../../util/urls";
import CookieForm from "../Consent/Cookie";
import GptAd from "../GPT/GptAd";
import BufferLoader from "../Loader/BufferLoader";
import { useEffect } from "react";

const PlayerBanner = () => {
  const { adContainer, bannerH, player } = usePlayerContext();
  const adPlaying = useSelector(adsState.selectPlaying);
  const prerollPlayed: boolean = useSelector(adsState.selectPrerollPlayed);
  const publisherConfig = useSelector(selectConfig);
  const configError = useSelector(selectConfigError);


  const dispatch = useDispatch();


  const timeElapsed = prerollPlayed ? player.currentTime : 0;
  
  useEffect(() => {
    if(player?.currentTime === 1)
    dispatch(setError(''))
  }, [player?.currentTime]);

  if (configError.error) {
    return (
      <Stack h={250} bg={publisherConfig.colors.primary}>
        <Box
          display={{ base: "block", lg: "flex" }}
          justifyContent="center"
          alignItems="center"
          margin="auto"
          color="white"
        >
          An error has occured please check the portal to make sure all settings
          are correct
        </Box>
      </Stack>
    );
  }

  return (
    <VStack
      id={config.bannerId}
      bg={publisherConfig.colors.primary || "gradient.purple"}
      h={bannerH}
    >
      <Flex
        justifyContent="center"
        id="adContainer"
        ref={adContainer}
      />

      <Flex justifyContent="space-between" width="100%">
        <Box ml={1}>
          <Image
            boxSize="100px"
            objectFit="contain"
            src={publisherConfig.logo}
          />
        </Box>
        <Flex
          mr={1}
          justifyContent="flex-end"
          display={adPlaying ? "none" : "flex"}
        >
          <PoweredByLogo />
        </Flex>
      </Flex>

      <BufferLoader />
      {timeElapsed < 30 && (
        <Box mt="auto" id={config.companionElementId} width={300} height={250} />
      )}
      {timeElapsed > 30 && (
        <Box mt="auto" id="gptBannerDiv">
          <GptAd path={publisherConfig.gptUrl} />
        </Box>
      )}
      {!prerollPlayed && !adPlaying && (
        <Box mt="auto" id="gptBannerDiv">
          <GptAd path={publisherConfig.gptUrl} />
        </Box>
      )}
       {
        !adPlaying && <CookieForm />
      }
    </VStack>
  );
};

export default PlayerBanner;

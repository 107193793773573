import { useDispatch, useSelector } from "react-redux";
import { requestAd } from "./requestAds";
import * as adsState from "../../../redux/slices/adsSlice";
import {
  selectConfig,
  selectPlaying,
  setBuffering,
  setEnded,
  setLoading,
} from "../../../redux/slices/playerSlice";
import { useEffect } from "react";
import { useGaSendEvent } from "../../GA";
import { config } from "../../../../config";

interface InitImaProps {
  setAdDisplayContainer: (
    adDisplayContainer: google.ima.AdDisplayContainer
  ) => void;
  setAdsManager: (adsManager: google.ima.AdsManager) => void;
  setStream: (status: boolean) => void;
}

export const useInitIma = ({
  setAdDisplayContainer,
  setAdsManager,
  setStream,
}: InitImaProps) => {
  const dispatch = useDispatch();
  const { sendEvent } = useGaSendEvent();
  const adPlaying = useSelector(adsState.selectPlaying);
  const adUrl = useSelector(selectConfig).adUrl;
  const playing = useSelector(selectPlaying);



  useEffect(() => {
    if (typeof google === "object" && typeof google.ima === "object") {
      if (adUrl !== "default") {
        dispatch(setLoading(false));

        let adsManager: google.ima.AdsManager;
        const videoContent = document.getElementById(
          config.contentElementId
        ) as HTMLVideoElement;
        const adContainer: HTMLElement | null =
          document.getElementById("adContainer");
        if (videoContent && adContainer) {
          google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
          const adDisplayContainer = new google.ima.AdDisplayContainer(
            adContainer,
            videoContent
          );

          setAdDisplayContainer(adDisplayContainer);

          const adsLoader = new google.ima.AdsLoader(adDisplayContainer);
          google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
          const playContent = () => {
            setStream(true);
            dispatch(adsState.setPlaying(false));
          };
          const onAdEvent = (adEvent: google.ima.AdEvent) => {
            let intervalTimer;
            const ad = adEvent.getAd();
            /* @ts-expect-error */
            switch (adEvent.type) {
              case google.ima.AdEvent.Type.LOADED:
                if (!ad!.isLinear()) {
                  playContent();
                }
                break;
              case google.ima.AdEvent.Type.STARTED:
                dispatch(adsState.setPlaying(true));
                if (ad!.isLinear()) {
                  intervalTimer = setInterval(function () {
                    // Example: const remainingTime = adsManager.getRemainingTime();
                  }, 300); // every 300ms
                }
                break;
              case google.ima.AdEvent.Type.AD_BUFFERING:
                dispatch(setBuffering(true));
                break;
              case google.ima.AdEvent.Type.CLICK:
                sendEvent({
                  category: "clickthrough_event",
                  action:
                    adEvent.getAd()!.getContentType() === "video/mp4"
                      ? "IMA_video_ad_clickthrough"
                      : "IMA_audio_ad_clickthrough",
                  label: "clickthrough",
                  nonInteraction: false,
                });
                dispatch(adsState.setPlaying(false));
                adsManager.stop();
                break;

              case google.ima.AdEvent.Type.AD_PROGRESS:
                dispatch(setBuffering(false));
                break;

              case google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED:

                if (ad!.isLinear()) {
                  clearInterval(intervalTimer);
                }
                dispatch(adsState.setPrerollePlayed(true));
                dispatch(setEnded(false));
                dispatch(adsState.setLoading(false)); 
                dispatch(setLoading(false));

                playContent();
                break;
            }
          };

          const onAdsManagerLoaded = (
            adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent
          ) => {
            /* @ts-ignore */
            const adsRenderingSettings = new google.ima.AdsRenderingSettings();
            adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete =
              true;
            dispatch(adsState.setLoading(false));

            adsManager = adsManagerLoadedEvent.getAdsManager(
              videoContent,
              adsRenderingSettings
            );
            setAdsManager(adsManager);

            // Add listeners to the required events.
            adsManager.addEventListener(
              google.ima.AdErrorEvent.Type.AD_ERROR,
              (adErrorEvent: google.ima.AdErrorEvent) => {
                sendEvent({
                  category: "error_event",
                  action: "ad_error",
                  label: "Ad Error",
                  nonInteraction: true,
                });
                dispatch(adsState.setError("error occured in the ads player"));
                adsManager!.destroy();
              }
            );

            adsManager.addEventListener(
              google.ima.AdEvent.Type.LOADED,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.STARTED,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.CLICK,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.INTERACTION,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.AD_BUFFERING,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.AD_PROGRESS,
              onAdEvent
            );

            adsManager.addEventListener(
              google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
              onAdEvent
            );
            adsManager.addEventListener(
              google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
              onAdEvent

            );
          };

          adsLoader.addEventListener(
            /* @ts-ignore */
            google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            onAdsManagerLoaded,
            false
          );

          adsLoader.addEventListener(
            /* @ts-ignore */
            google.ima.AdErrorEvent.Type.AD_ERROR,
            (adErrorEvent: google.ima.AdErrorEvent) => {
              console.log("Ad error: ", adErrorEvent);

              dispatch(adsState.setLoading(false));
              document
                .getElementById("atunwa-player-play-button")
                ?.addEventListener("click", () => {
                  playContent();
                });

              if (adsManager) adsManager.destroy();
            },
            false
          );

          const contentEndedListener = function () {
            if (adPlaying) return;
            dispatch(setEnded(true));
            adsLoader.contentComplete();
          };

          videoContent.onended = contentEndedListener;
          requestAd({ adsLoader, url: adUrl });
        }

      } else {
        dispatch(adsState.setLoading(false));
        dispatch(setLoading(false));
        dispatch(adsState.setPrerollePlayed(true));

      }

    }
  }, [adUrl, playing]);
};

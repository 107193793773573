import { AdsProps } from "../../../../components/types";

const RefreshSlot = ({ slot, pubads }: AdsProps) => {
  if (slot) {
    googletag.cmd.push(() => {
      if (slot) pubads.refresh([slot]);
    });
  }
};

export default RefreshSlot;

import { getCookieConsentValue } from "react-cookie-consent";
import { config } from "../../../../config";

const initAdwizz = () => {
  /* @ts-expect-error */
  if (window.adswizzSDK) {
    /* @ts-expect-error */
    if (window.adswizzSDK) {
      /* @ts-expect-error */
      window.adswizzSDK.init({
        listenerConsent: false,
        contentPlayer: document.getElementById(config.contentElementId),
        playerId: "atunwa_web",
        companionBanner: {
          container: document.getElementById(config.companionElementId),
          size: [300, 250],
          baseURL:
            "https://atunwa.deliveryengine.adswizz.com/www/delivery/afr.php",
          zoneId: "2459",
          fallbackZoneId: "2461",
          alwaysDisplayAds: true,
        },
      });
    }
  }
};

export default initAdwizz;

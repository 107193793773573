import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface StreamInfo {
  isVideo: boolean;
  isAudio: boolean;
}

interface PlayerState {
  loading: boolean;
  playing: boolean;
  volume: number;
  refreshCount: number
  isFullscreen: boolean;
  metadata: any;
  ended: boolean;
  error: string;
  playClicked: number;
  buffering: boolean;
  streamInfo: StreamInfo;
  adblockerEnabled: boolean;
  configError: {
    error: boolean;
  };
  config: {
    loading: boolean;
    streamURL: string;
    playerName: string;
    websiteName: string;
    assetKey: string
    domain: string
    publisher: {
      contactPersonId: string
      country: string
      dateAdded: string
      facebook: string
      id: string
      instagram: string
      isArchived: string
      linkedIn: string
      loadingAnimation: string
      logo: string
      name: string
      twitter: string
      website: string
      whatsapp: string
    }
    colors: {
      primary: string;
      secondary: string;
      text: string;
      highlight: string;
    };
    logo: string;
    loadingAnimation: string;
    adUrl: string;
    gptUrl: string;
  };
}

const initialState: PlayerState = {
  loading: true,
  playing: false,
  volume: 0.5,
  refreshCount: 0,
  isFullscreen: false,
  metadata: { title: "", name: "" },
  ended: false,

  error: "",
  playClicked: 0,
  buffering: false,
  streamInfo: {
    isAudio: false,
    isVideo: false
  },
  adblockerEnabled: false,
  configError: {
    error: false,
  },
  config: {
    loading: true,
    streamURL: "",
    assetKey: '',
    playerName: "",
    websiteName: "",
    domain: '',
    publisher: {
      contactPersonId: '',
      country: '',
      dateAdded: '',
      facebook: '',
      id: '',
      instagram: '',
      isArchived: '',
      linkedIn: '',
      loadingAnimation: '',
      logo: '',
      name: '',
      twitter: '',
      website: '',
      whatsapp: '',
    },
    colors: {
      primary: "gradient.purple",
      secondary: "primary.white",
      text: "primary.white",
      highlight: "gray.500",
    },
    logo: "https://www.dev.atunwadigital.com/assets/atunwa_logo-79836885.svg",
    loadingAnimation: "",
    adUrl: "",
    gptUrl: "",
  },
};

const slice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setConfigError: (
      state,
      action: PayloadAction<PlayerState["configError"]>
    ) => {
      state.configError = action.payload;
    },
    setConfig: (state, action: PayloadAction<PlayerState["config"]>) => {
      state.config = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.playing = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    setRefreshCount: (state, action: PayloadAction<number>) => {
      state.refreshCount = action.payload;
    },
    setIsFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
    setEnded: (state, action: PayloadAction<boolean>) => {
      state.ended = action.payload;

    },

    setMetadata: (state, action: PayloadAction<object>) => {
      state.metadata = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setPlayClicked: (state) => {
      state.playClicked = state.playClicked + 1;
    },
    setBuffering: (state, action: PayloadAction<boolean>) => {
      state.buffering = action.payload;
    },
    setStreamInfo: (state, action: PayloadAction<StreamInfo>) => {
      state.streamInfo = action.payload;
    },
    setAdblockerEnabled: (state, action: PayloadAction<boolean>) => {
      state.adblockerEnabled = action.payload;
    },
  },
});

export const {
  setLoading,
  setPlaying,
  setMetadata,
  setVolume,
  setIsFullscreen,
  setEnded,
  setError,
  setPlayClicked,
  setBuffering,
  setStreamInfo,
  setConfig,
  setConfigError,
  setAdblockerEnabled
} = slice.actions;

export const selectLoading = (state: { player: PlayerState }) =>
  state.player.loading;

export const selectPlaying = (state: { player: PlayerState }) =>
  state.player.playing;

export const selectVolume = (state: { player: PlayerState }) =>
  state.player.volume;

export const selectIsFullscreen = (state: { player: PlayerState }) =>
  state.player.isFullscreen;

export const selectMetadata = (state: { player: PlayerState }) =>
  state.player.metadata;

export const selectEnded = (state: { player: PlayerState }) =>
  state.player.ended;


export const selectError = (state: { player: PlayerState }) =>
  state.player.error;

export const selectPlayClicked = (state: { player: PlayerState }) =>
  state.player.playClicked;

export const selectBuffering = (state: { player: PlayerState }) =>
    state.player.buffering;

export const selectStreamInfo = (state: { player: PlayerState }) =>
    state.player.streamInfo;

  export const selectAdblockerEnabled = (state: { player: PlayerState }) =>
  state.player.adblockerEnabled;

export const selectConfig = (state: { player: PlayerState }) =>
  state.player.config;

export const selectConfigError = (state: { player: PlayerState }) =>
  state.player.configError;

export default slice.reducer;

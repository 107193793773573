import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectMetadata, selectError } from "../../../redux/slices/playerSlice";
import { selectData, selectPlaying } from "../../../redux/slices/adsSlice";
import { TooltipText } from "../../../util/formatText";
import { usePlayerContext } from "../../../providers/PlayerProvider.tsx";

const StreamMeta = () => {
  const metadata = useSelector(selectMetadata);
  const error = useSelector(selectError);
  const playing = useSelector(selectPlaying);
  const adsData = useSelector(selectData);
  const { liveBadgeH } = usePlayerContext();

  return (
    <Box minH={liveBadgeH}>
      <Box fontSize={10} fontWeight="bold" color="primary.white">
        {playing
          ? TooltipText({ text: adsData.title, maxLength: 30 }) || "Ads"
          : TooltipText({ text: metadata?.title, maxLength: 30 })}
      </Box>
      <Box fontSize={11} color="primary.white">
        {playing
          ? TooltipText({ text: adsData.description, maxLength: 30 }) ||
            "Sponsored content playing"
          : TooltipText({ text: metadata?.name, maxLength: 30 })}
      </Box>
      <Box fontSize={10} mt={2} color="primary.error" textAlign="right">
        {error}
      </Box>
    </Box>
  );
};

export default StreamMeta;

import { Box, Collapse, Stack } from "@chakra-ui/react";
import { usePlayerContext } from "../../providers/PlayerProvider.tsx";
import PlayerBanner from "../PlayerOverlay/PlayerBanner";
import { usePageLoadMetrics } from "../../hooks/GA";
import ReactHlsPlayer from "../../hooks/Player/index.js";
import usePlayerData from "../../hooks/usePlayerData";
import { ControlsOverlay } from "../PlayerOverlay/ControlOverlay/index.js";
import { getCookieConsentValue } from "react-cookie-consent";
import { useEffect, useState } from "react";


const AtunwaPlayerComponent = ({ streamUrl }: { streamUrl: string }) => {
  const [viewHeight, setViewHeight] = useState(500);
  const { uiContainerRef, audio, bannerH } = usePlayerContext();
  usePageLoadMetrics();
  usePlayerData();

  function iniFrame() {
    if (window.self !== window.top) {
      const vh = window.innerHeight || 0;
      setViewHeight(vh);
    }
  }

  useEffect(() => {
    iniFrame();
  }, []);

  return (
    //check if this is in iframe
    <Stack
      bg="secondary.dark"
      p={1}
      ref={uiContainerRef}
      height={`${viewHeight}px`}
    >
      <Collapse
        startingHeight={bannerH}
        in={typeof getCookieConsentValue() === "string"}
      >
        <PlayerBanner />
      </Collapse>

      <Box hidden>
        <ReactHlsPlayer
          src={streamUrl}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
          playerRef={audio}
        />
      </Box>
      <ControlsOverlay />
    </Stack>
  );
};

export default AtunwaPlayerComponent;

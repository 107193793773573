interface EnvConfig {
  mode: string;
  analyticMeasurementId: string;
  googleApiKey: string;
  baseUrl: string;
  playerSecretKey: string;
  width: number | undefined;
  height: number | undefined;
  bannerId: string
  contentElementId: string
  companionElementId: string
  controlsId: string
}

const computeEnv = (): EnvConfig => {
  const parentContainer = document.getElementById("atunwa-player");
  const h = parentContainer?.clientHeight;
  const w = parentContainer?.clientWidth;

  if (typeof process === "object") {
    return {
      mode: process.env.VITE_NODE_ENV_MODE ?? "",
      baseUrl: `${process.env.VITE_BASE_URL}/graphql` ?? "",

      playerSecretKey: process.env.VITE_PLAYER_SECRET_KEY ?? "",
      analyticMeasurementId:
        process.env.VITE_GOOGLE_ANALYTIC_MEASUREMENT_ID ?? "",
      googleApiKey:
       process.env.VITE_GOOGLE_API_KEY ?? "",
      height: h,
      width: w,
      bannerId: 'atunwa-player-banner-element',
      contentElementId: 'atunwa-player-content-element',
      companionElementId: 'atunwa-player-companion-element',
      controlsId: 'atunwa-player-control-container'
    };
  } else {
    return {
      mode: import.meta.env?.VITE_NODE_ENV_MODE ?? "",
      baseUrl: `${import.meta.env.VITE_BASE_URL}/graphql` ?? "",

      playerSecretKey: import.meta.env.VITE_PLAYER_SECRET_KEY ?? "",
      analyticMeasurementId:
        import.meta.env?.VITE_GOOGLE_ANALYTIC_MEASUREMENT_ID ?? "",
      googleApiKey:
          import.meta.env?.VITE_GOOGLE_API_KEY ?? "",
      height: h,
      width: w,
      bannerId: 'atunwa-player-banner-element',
      contentElementId: 'atunwa-player-content-element',
      companionElementId: 'atunwa-player-companion-element',
      controlsId: 'atunwa-player-control-container'

    };
  }
};

export const config: EnvConfig = computeEnv();

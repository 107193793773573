import React from 'react'

const CookieIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.9996 27.9445C37.0086 29.1372 36.7796 30.3197 36.3261 31.4229C35.8726 32.5261 35.2036 33.5277 34.3584 34.3693C30.8947 37.8269 25.236 37.8658 21.7275 34.4537C20.4398 33.2023 19.5559 31.5943 19.1895 29.8365C18.823 28.0788 18.9907 26.2516 19.671 24.5899C20.3513 22.9282 21.5131 21.5079 23.007 20.5118C24.501 19.5157 26.2587 18.9893 28.0542 19.0002C28.1667 19.0004 28.2777 19.0259 28.3789 19.0747C28.4802 19.1235 28.5693 19.1945 28.6395 19.2823C28.7097 19.3701 28.7593 19.4726 28.7847 19.5821C28.81 19.6917 28.8104 19.8055 28.7859 19.9153C28.6875 20.3515 28.6879 20.8042 28.7871 21.2403C28.8862 21.6763 29.0816 22.0847 29.359 22.4355C29.6363 22.7863 29.9886 23.0706 30.39 23.2677C30.7915 23.4647 31.2319 23.5695 31.679 23.5744C31.8765 23.5761 32.0654 23.6552 32.205 23.7948C32.3446 23.9345 32.4237 24.1233 32.4254 24.3208C32.4303 24.7679 32.5351 25.2083 32.7321 25.6097C32.9292 26.0112 33.2135 26.3634 33.5643 26.6408C33.9151 26.9181 34.3234 27.1136 34.7595 27.2127C35.1955 27.3119 35.6482 27.3123 36.0845 27.2139C36.1941 27.1893 36.3079 27.1897 36.4174 27.215C36.5269 27.2402 36.6293 27.2897 36.7171 27.3599C36.805 27.43 36.8759 27.5189 36.9248 27.6201C36.9737 27.7212 36.9993 27.8321 36.9996 27.9445V27.9445Z" stroke="#2B3C60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.625 33.25C31.2463 33.25 31.75 32.7463 31.75 32.125C31.75 31.5037 31.2463 31 30.625 31C30.0037 31 29.5 31.5037 29.5 32.125C29.5 32.7463 30.0037 33.25 30.625 33.25Z" fill="#2B3C60"/>
    <path d="M24.625 32.5C25.2463 32.5 25.75 31.9963 25.75 31.375C25.75 30.7537 25.2463 30.25 24.625 30.25C24.0037 30.25 23.5 30.7537 23.5 31.375C23.5 31.9963 24.0037 32.5 24.625 32.5Z" fill="#2B3C60"/>
    <path d="M23.875 27.25C24.4963 27.25 25 26.7463 25 26.125C25 25.5037 24.4963 25 23.875 25C23.2537 25 22.75 25.5037 22.75 26.125C22.75 26.7463 23.2537 27.25 23.875 27.25Z" fill="#2B3C60"/>
    <path d="M28.75 28.75C29.3713 28.75 29.875 28.2463 29.875 27.625C29.875 27.0037 29.3713 26.5 28.75 26.5C28.1287 26.5 27.625 27.0037 27.625 27.625C27.625 28.2463 28.1287 28.75 28.75 28.75Z" fill="#2B3C60"/>
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#D9C6DD" stroke-width="8"/>
    </svg>
    
  )
}

export default CookieIcon
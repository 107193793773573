import { RefObject } from "react";
import { config } from "../../../../config";

interface PlayAdsProps {
  adDisplayContainer: google.ima.AdDisplayContainer;
  adsManager: google.ima.AdsManager;
  handlePlay: () => void;
  adContainer: RefObject<HTMLElement>;
}
export const playAds = ({
  adDisplayContainer,
  adsManager,
  handlePlay,
}: PlayAdsProps) => {
  adDisplayContainer.initialize();
  const w = document.getElementById(config.bannerId)?.parentElement?.clientWidth!;
  const h = document.getElementById(config.bannerId)?.parentElement?.clientHeight!

  try {
    adsManager.init(w, h, google.ima.ViewMode.NORMAL);
    adsManager.start();
  } catch (adError) {
    // An error may be thrown if there was a problem with the VAST response.
    handlePlay();
  }
};

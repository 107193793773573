import {Spinner, Box, Center, Text, Stack, Image} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  selectBuffering,
  selectConfig,
  selectLoading,
} from "../../redux/slices/playerSlice";
import { selectLoading as adIsLoading, selectPlaying as adIsPlaying } from "../../redux/slices/adsSlice";
import { config } from "../../../config";
import { useEffect, useState } from "react";

const BufferLoader = () => {
  const buffering = useSelector(selectBuffering);
  const loading = useSelector(selectLoading);
  const adLoading = useSelector(adIsLoading);
  const adPlaying = useSelector(adIsPlaying);
  const spinnerColor = useSelector(selectConfig).colors.secondary;
  const publisherConfig = useSelector(selectConfig);

  const [msg, setMsg] = useState<string>('')
  useEffect(() => {
    if (buffering) setMsg('Stream buffering...')
    else if (loading) setMsg('Connecting...')
    else if (adLoading) {
      adPlaying ? setMsg('Ad buffering...') : setMsg('Readying...')
    }
  }, [loading, buffering, adLoading])

  return buffering || adLoading || loading ? (
    <Center
      position="absolute"
      w={document.getElementById(config.bannerId)?.parentElement?.clientWidth}
      h={document.getElementById(config.bannerId)?.parentElement?.clientHeight!}
      zIndex="overlay"
      bg="rgba(0, 0, 0, 0.4)"
      style={{ transition: 'all .5s ease'}}
    >
      <Stack align='center'>
        {
          !!publisherConfig?.loadingAnimation ? (
              <Box ml={1}>
                <Image
                    boxSize="100px"
                    objectFit="contain"
                    src={publisherConfig.loadingAnimation}
                />
              </Box>
          ) : <Spinner size="xl" color={spinnerColor} style={{ transition: 'all .5s ease'}} />

        }

        <Text style={{ transition: 'all .5s ease'}} color={spinnerColor}>{msg}</Text>
      </Stack>

    </Center>
  ) : (
    <Box />
  );
};

export default BufferLoader;

import {  useRef, useState } from "react";
import useGpt from "../hooks/Ads/GPT";
import { useInitIma } from "../hooks/Ads/IMA/useInitIma";
import { useDispatch, useSelector } from "react-redux";
import { setPlaying } from "../redux/slices/playerSlice";
import { playAds } from "../hooks/Ads/IMA/playAds";
import AtunwaAppComponent from "./AtunwaAppComponent";

function AtunwaApp() {
  const adContainer = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const [stream, setStream] = useState<boolean>(false);
  const [adDisplayContainer, setAdDisplayContainer] =
    useState<google.ima.AdDisplayContainer>();
  const [adsManager, setAdsManager] = useState<google.ima.AdsManager>();


  const initiateAds = () => {
    useInitIma({ setAdDisplayContainer, setAdsManager, setStream });
    const GPTAdsManager = useGpt();
    GPTAdsManager?.initializeAds(false, true);
  };

  const handlePlay = () => {
    playAds({
      adDisplayContainer: adDisplayContainer!,
      adsManager: adsManager!,
      adContainer,
      handlePlay: () => dispatch(setPlaying(true)),
    });
  };

  return (
    <AtunwaAppComponent
      handlePlay={handlePlay}
      initiateAds={initiateAds}
      stream={stream}
    />
  );
}

export default AtunwaApp;

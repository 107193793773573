export const decorateUrl = (url: string) => {

     if (!url)
          return url;

     let streamUrl = `${url}&test=true`;

     /* @ts-ignore */
     streamUrl = window.adswizzSDK?.decorateURL(streamUrl);

     return streamUrl;
}

const decorateURLAndCreateSession = (url: string): Promise<string> => {
     return new Promise((resolve, reject) => {
          if (!window.adswizzSDK) {
               return reject('Adswizz SDK not found');
          }

          try {
               window.adswizzSDK.decorateURLAndCreateSession(
                   url,
                   (decoratedStreamUrl: string) => {
                        if (decoratedStreamUrl) {
                             resolve(decoratedStreamUrl)
                        } else {
                             reject('Adswizz SDK failed to decorate the URL')
                        }
                   },
               )
          } catch (error) {
               debugger;
               reject(error)
          }

     })
}

export default decorateURLAndCreateSession
import {ApolloProvider} from "@apollo/client";
import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {client} from "../client";
import {store, persistor} from "../redux/store";
import {ChakraProvider} from "@chakra-ui/react";
import theme from "../theme";
import {CSSReset} from "@chakra-ui/css-reset";
import AtunwaPlayerBundle from "./AtunwaPlayerBundle";
import AtunwaPlayerMeta from "./AtunwaPlayerMeta";


const AtunwaPlayer = () => (
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading="loading">
                <ApolloProvider client={client}>
                    <ChakraProvider theme={theme} resetCSS={false} cssVarsRoot="#atunwa-player"
                                    resetScope=".atunwa-player">
                        <CSSReset scope=".atunwa-player"/>
                        <AtunwaPlayerMeta/>
                        <AtunwaPlayerBundle/>
                    </ChakraProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);


export default AtunwaPlayer;

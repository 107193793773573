import { useEffect, useRef, useState } from "react";
import AtunwaPlayerComponent from "../components/AtunwaPlayerComponent";
import PlayerContextProvider from "../providers/PlayerProvider.tsx";
import { useSelector } from "react-redux";
import {
  selectConfig,
  selectPlayClicked,
  selectPlaying,
} from "../redux/slices/playerSlice";
import initAdwizz from "../hooks/Ads/Adswizz/initAdwizz";

interface AtunwaAppComponentProps {
  handleAdblocker?: () => void;
  initiateAds?: () => void;
  handlePlay: () => void;
  stream: boolean;
}

function AtunwaAppComponent({
  handleAdblocker = () => {},
  handlePlay,
  stream
}: AtunwaAppComponentProps) {
  const audio = useRef<HTMLVideoElement | null>(null);
  const uiContainerRef = useRef<HTMLDivElement | null>(null);
  const adContainer = useRef<HTMLDivElement | null>(null);
  const [streamUrl, setStreamUrl] = useState<string>("");
  const playClicked = useSelector(selectPlayClicked);
  const playing = useSelector(selectPlaying);
  const publisherConfig = useSelector(selectConfig);

  useEffect(() => {
    initAdwizz();

    handleAdblocker();
  }, []);

  useEffect(() => {
    if (stream) {
      setStreamUrl(publisherConfig.streamURL);
    }
  }, [stream]);

  useEffect(() => {
    if (playing && playClicked === 1) {
      handlePlay();
    }
  }, [playing, playClicked]);

  return (
    <PlayerContextProvider
      uiContainerRef={uiContainerRef}
      audio={audio}
      adContainer={adContainer}
    >
      <AtunwaPlayerComponent streamUrl={publisherConfig.streamURL} />
    </PlayerContextProvider>
  );
}

export default AtunwaAppComponent;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EllapsedProps, HlsPlayerProps } from "../../components/types";
import { selectLoading as adLoading } from "../../redux/slices/adsSlice";
import {
  selectConfig,
  selectPlayClicked,
  selectPlaying,
  selectVolume,
  setLoading,
  setPlayClicked,
  setPlaying,
  setVolume,
} from "../../redux/slices/playerSlice";
import { formatTime } from "../../util/formatTime";
import { playContent } from "../../util/playContent";
import { useGaSendEvent } from "../GA";
import { config } from "../../../config";
import * as adsState from "../../redux/slices/adsSlice";
import { useInitIma } from "../Ads/IMA/useInitIma";
import { playAds } from "../Ads/IMA/playAds";

const usePlayerActions = ({ audio, uiContainerRef }: HlsPlayerProps) => {
  let intervalId: any = null;
  let timeoutId: any = null;
  const videoContent = document.getElementById(
    config.contentElementId
  ) as HTMLVideoElement;

  const FIVEMINUTES = 5 * 60 * 1000;
  const TWENTYFOURHOURS = 24 * 60 * 60; // equals 86400 seconds

  const playing = useSelector(selectPlaying);
  const volume = useSelector(selectVolume);
  const playClicked = useSelector(selectPlayClicked);
  const adIsLoading = useSelector(adLoading);
  const adPlaying = useSelector(adsState.selectPlaying);

  const dispatch = useDispatch();
  const adContainer = useRef<HTMLDivElement | null>(null);
  const [stream, setStream] = useState<boolean>(false);
  const [adDisplayContainer, setAdDisplayContainer] =
    useState<google.ima.AdDisplayContainer>();
  const [adsManager, setAdsManager] = useState<google.ima.AdsManager>();
  const adUrl = useSelector(selectConfig).adUrl;


  useInitIma({ setAdDisplayContainer, setAdsManager, setStream });

  /* google analytics send event */
  const { sendEvent } = useGaSendEvent();

  const [counter, setCounter] = useState<EllapsedProps>({
    sec: "00",
    min: "00",
    hour: "00",
  });
  const [duration, setDuration] = useState<EllapsedProps>({
    sec: "00",
    min: "00",
    hour: "00",
  });

  const play = () => {
    dispatch(setPlayClicked());
    dispatch(setPlaying(!playing));
  };

  useEffect(() => {
    !adPlaying && playAudio();
  }, [adPlaying]);

  const playImaAds = () => {
    if (audio.current && playClicked > 1) {
      dispatch(adsState.setPlaying(true));
      playAds({
        adDisplayContainer: adDisplayContainer!,
        adsManager: adsManager!,
        adContainer,
        handlePlay: () => dispatch(setPlaying(true)),
      });
    }
  };

  const playAudio = async () => {
    if (audio.current && playClicked > 1) {
      playContent({
        playerRef: audio,
        dispatch,
        cb: () => {
          sendEvent({
            category: "play_event",
            action: playClicked ? "play" : "auto_play",
            label: "Play Stream",
            nonInteraction: !playClicked,
          });
        },
        errMsg: "Error in initiating play action",
      });
    }
  };

  const pauseAudio = () => {
    if (audio.current && !adIsLoading) {
      audio.current?.pause();
      sendEvent({
        category: "pause_event",
        action: playClicked ? "pause" : "auto_pause",
        label: "Pause Stream",
        nonInteraction: !playClicked,
      });
      playClicked > 0 && dispatch(setLoading(false));
    }
  };
  const changeVolume = (value: number) => {
    dispatch(setVolume(value));
    if (audio.current) {
      dispatch(setVolume(value));
      sendEvent({
        category: "volume_event",
        action: "volume_change",
        label: "Volume Change",
        nonInteraction: false,
      });
    }
  };

  useEffect(() => {
    if (audio.current) {
      audio.current.volume = volume;
    }
  }, [volume, audio]);

  useEffect(() => {
    if (audio.current) {
      if (playing) {
        if (adUrl !== "default") playImaAds()
        else playAudio();
      } else pauseAudio();
    }
  }, [playing]);

  const ellapsed = () => {
    if (playing && audio.current) {
      const formattedEllapsed = formatTime(videoContent.currentTime);

      const formattedDuration =
        videoContent.duration === Infinity
          ? formatTime(TWENTYFOURHOURS)
          : formatTime(videoContent.duration);

      setCounter(formattedEllapsed);
      setDuration(formattedDuration);

      return { ellapsed: formattedEllapsed, duration: formattedDuration };
    }
  };

  const runElapsed = () => {
    setInterval(() => {
      if (videoContent.duration) {
        ellapsed();
      }
    }, 1000);
  };

  useEffect(() => {
    /* time spent listening tracked after 5 minutes of inactivity */
    const ISNOTPLAYING = playing === false;

    timeoutId = setTimeout(() => {
      if (
        ISNOTPLAYING &&
        audio.current &&
        uiContainerRef.current &&
        audio.current?.currentTime > 0
      ) {
        sendEvent({
          category: "listening_time_event",
          action: "listening_time_after_inactivity",
          label: "Listening Time",
          value: audio.current?.currentTime,
          nonInteraction: false,
        });
      }
    }, FIVEMINUTES);

    /* ellapsed time  */
    if (playing && audio.current && uiContainerRef.current) {
      intervalId = runElapsed();
    }
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [audio, playing]);

  const props = {
    play,
    changeVolume,
    duration,
    runElapsed,
    ellapsed: counter,
    audio,
    uiContainerRef,
    player: videoContent,
  };

  return props;
};

export default usePlayerActions;

import { gql, useLazyQuery } from "@apollo/client";
import { AES, enc } from "crypto-js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../config";
import { setConfigError, setConfig } from "../redux/slices/playerSlice";
import {
  selectAdswizzConsent,
} from "../redux/slices/adsSlice";
import { getCookieConsentValue } from "react-cookie-consent";

const usePlayerData = () => {
  const dispatch = useDispatch();
  const adswizzConsent = useSelector(selectAdswizzConsent)
  const consentValue = typeof getCookieConsentValue() === "string" ? getCookieConsentValue() === 'true' : adswizzConsent ;

  const getAPIKey = document
    .getElementById("atunwa-player")
    ?.getAttribute("data-apiKey");

  const GET_PLAYER = gql`
    query playerByAPIKey($apiKey: String!) {
      playerByAPIKey(apiKey: $apiKey)
    }
  `;
  const [playerByAPIKey] = useLazyQuery(GET_PLAYER, {
    variables: {
      apiKey: getAPIKey,
    },
  });

  const formatStreamUrl = (streamUrl: string) => {
    const url = new URL(streamUrl);
    if (!url.searchParams.get("aw_0_req.gdpr")) {
      streamUrl = streamUrl.concat(`?aw_0_req.gdpr=${consentValue}`);
    }
    if (!url.searchParams.get("us_privacy")) {
      const consentVariable = consentValue ? "Y" : "N";
      streamUrl = streamUrl.concat(`?us_privacy=1Y${consentVariable}N`);
    }
    return streamUrl;
  };

  useEffect(() => {
    playerByAPIKey()
      .then((data) => {
        const bytes = AES.decrypt(
          data.data.playerByAPIKey,
          config.playerSecretKey
        );
        const decryptedData = JSON.parse(bytes.toString(enc.Utf8));

        if (data) {
          dispatch(
            setConfigError({
              error: false,
            })
          );

          dispatch(
            setConfig({
              assetKey: "",
              loading: false,
              streamURL: formatStreamUrl(decryptedData.url),
              playerName: decryptedData.name,
              websiteName: decryptedData.website,
              colors: decryptedData.colors,
              logo: decryptedData.logo || decryptedData.publisher.logo,
              domain: decryptedData.authorisedDomain.url,
              publisher: decryptedData.publisher,
              loadingAnimation:
                decryptedData.loadingAnimation ||
                decryptedData.publisher.loadingAnimation,
              adUrl: decryptedData.imaUrl || "default",
              gptUrl: decryptedData.gptUrl || "",
            })
          );
        } else {
          dispatch(
            setConfigError({
              error: true,
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          setConfigError({
            error: true,
          })
        );
      });
  }, []);
};

export default usePlayerData;

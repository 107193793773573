import dashjs from "dashjs";
import Hls, {HlsConfig} from "hls.js";
import React, {RefObject, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectMetadata, setBuffering} from "../../redux/slices/playerSlice";
import {get_url_extension, handleTextMetadata} from "../../util/formatText";
import {playContent} from "../../util/playContent";
import {useGaSendEvent} from "../GA";
import _initHlsPlayer from "./initHlsPlayer";
import {config} from "../../../config";
import * as adsState from "../../redux/slices/adsSlice";
import * as playerState from "../../redux/slices/playerSlice";
import decorateURLAndCreateSession from "../Ads/Adswizz/decorateUrl";


export interface HlsPlayerProps
    extends React.VideoHTMLAttributes<HTMLVideoElement> {
    hlsConfig?: HlsConfig;
    playerRef?: RefObject<HTMLVideoElement>;
    src: string;
}

function ReactHlsPlayer({
                            hlsConfig,
                            playerRef = React.createRef<HTMLVideoElement>(),
                            src,
                            autoPlay,
                            ...props
                        }: HlsPlayerProps) {
    const dispatch = useDispatch();
    const metadata = useSelector(selectMetadata);
    const {sendEvent} = useGaSendEvent();
    const prerolledPlayed = useSelector(adsState.selectPrerollPlayed);
    const streamInfo = useSelector(playerState.selectStreamInfo);
    const isPlaying = useSelector(playerState.selectPlaying);

    useEffect(() => {
        // fetch manifest to determine stream info
        if (get_url_extension(src) === "m3u8") {
            initHlsPlayer(src, autoPlay);
        }
    }, [src]);


    function playAudioWithSessionSupport(streamUrl: string) {
        if (streamInfo.isVideo) {
            playContent({playerRef, dispatch});
        } else {
            decorateURLAndCreateSession(streamUrl).then(function (decoratedStreamURL: string) {
                initHlsPlayer(decoratedStreamURL, true)
            }).catch(function (error: any) {
                initHlsPlayer(streamUrl, true)
            })
        }
    }

    function initHlsPlayer(streamUrl: string, autoPlay = false) {
        if (Hls.isSupported()) {
            _initHlsPlayer({
                hlsConfig,
                playerRef,
                src: streamUrl,
                autoPlay,
                dispatch,
                sendEvent,
                streamUrl,
                metadata,
            });
        }
    }


    useEffect(() => {

            if (get_url_extension(src) === "mpd") {
                if (playerRef.current) {
                    var player = dashjs.MediaPlayer().create();
                    player.initialize(playerRef.current, src, false);

                    if (src && prerolledPlayed && isPlaying) {
                        playContent({dispatch, playerRef});
                    }
                }
            } else if (get_url_extension(src) === "m3u8") {
                let hls: Hls;

                if (src && prerolledPlayed && isPlaying) {
                    dispatch(setBuffering(true));
                    playerRef.current?.addEventListener("waiting", () => {
                        sendEvent({
                            category: "buffering_event",
                            action: "buffering",
                            label: "Player Buffering",
                            nonInteraction: true,
                        });
                    });
                    if (Hls.isSupported()) {
                        playAudioWithSessionSupport(src);
                    } else if (
                        playerRef.current?.canPlayType("application/vnd.apple.mpegurl")
                    ) {
                        if (src && prerolledPlayed) {
                            playerRef.current.src = src;
                            playContent({playerRef, dispatch});
                        }
                    }
                }

                playerRef.current?.addEventListener("loadedmetadata", (event: any) =>
                    handleTextMetadata({event, metadata, dispatch})
                );

                playerRef.current?.addEventListener("waiting", () => {
                    sendEvent({
                        category: "buffering_event",
                        action: "buffering",
                        label: "Player Buffering",
                        nonInteraction: true,
                    });
                    dispatch(setBuffering(true));
                });

                playerRef.current?.addEventListener("playing", () => {
                    dispatch(setBuffering(false));
                });

                return () => {
                    playerRef.current?.removeEventListener("waiting", () => {
                        dispatch(setBuffering(true));
                    });
                    playerRef.current?.removeEventListener("playing", () => {
                        dispatch(setBuffering(false));
                    });

                    if (hls != null) {
                        hls.destroy();
                    } else if (get_url_extension(src) === "mp3") {
                        if (src && playerRef?.current && prerolledPlayed && isPlaying) {
                            playerRef.current.src = src;
                            playContent({playerRef, dispatch});
                        }
                    } else {
                        if (src && playerRef?.current && prerolledPlayed && isPlaying) {
                            playerRef.current.src = src;
                            playContent({playerRef, dispatch});
                        }
                    }
                };

            } else {
                if (src && playerRef?.current && prerolledPlayed && isPlaying) {

                    playerRef.current.src = src;
                    playContent({playerRef, dispatch});
                }
            }
        },
        [autoPlay, src, prerolledPlayed, isPlaying]
    );


    return (
        <div>
            <video
                ref={playerRef}
                {...props}
                controls={false}
                id={config.contentElementId}
                playsInline
            />
        </div>
    );
}

export default ReactHlsPlayer;
